import { HTTP_METHOD_TYPE } from '../utils/constants';

export default function postProductTrackingService({
    $http, params, data,
}) {
    try {
        $http({
            method: HTTP_METHOD_TYPE.POST,
            url: `${$http.API.V3}marketing/tracking/log`,
            params,
            data,
        });
    } catch (error) {
        console.error('service postProductTrackingService Http: ', error);
    }
}
