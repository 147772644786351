import { fetchAddToCartRecommendationsService } from '../../services';

export default {
    actions: {
        async fetchAddToCartRecommendations({ commit }, { productID }) {
            try {
                const response = await fetchAddToCartRecommendationsService({
                    $http: this.$http,
                    params: {
                        identifier: productID,
                    },
                });

                if (response) {
                    commit('shared/SET_GLOBALS_DATA', {
                        prop: 'addToCartRecommendations',
                        data: {
                            items: response.products,
                            queryParams: response.queryParams,
                            badges: response.badges,
                        },
                        key: 'addToCartRecommendations',
                    }, { root: true });
                }
            } catch (error) {
                console.error('fetchAddToCartRecommendationsService: ', error);
            }
        },
    },
};
