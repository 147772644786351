<template>
    <div>
        <modal-user
            v-if="modals.user"
            v-model="modals.user.show"
            :type="modals.user.type"
            :data="modals.user.data"
        />

        <modal-gdpr
            v-if="modals.gdpr"
            v-model="modals.gdpr.show"
            :hide-btn-edit="modals.gdpr.data.hideBtnEdit"
            :show-screen-edit="modals.gdpr.data.showScreenEdit"
        />

        <modal-add-to-cart-recommendations
            v-if="modals.addToCartRecommendations"
            v-model="modals.addToCartRecommendations.show"
            :data="modals.addToCartRecommendations.data"
        />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const ModalUser = defineAsyncComponent(() => import('./ModalUser.vue'));
const ModalGdpr = defineAsyncComponent(() => import('./ModalGdpr.vue'));
const ModalAddToCartRecommendations = defineAsyncComponent(() => import('./ModalAddToCartRecommendations.vue'));

export default {
    name: 'TheModals',

    components: {
        ModalUser,
        ModalGdpr,
        ModalAddToCartRecommendations,
    },

    data: () => ({
        modals: {},
    }),

    mounted() {
        this.$emitter.on('modal', ({
            show = true,
            type,
            modal,
            data = {},
        }) => {
            const hideUserModalAfterLogin = (modal === 'user' && !type && this.modals.user);
            if (hideUserModalAfterLogin) {
                this.modals.user.show = false;
                return;
            }

            const stopsOpenModalWithoutType = (modal === 'user' && !type);
            if (stopsOpenModalWithoutType) return;

            // Using setTimeout to address INP (Interaction to Next Paint) CWW Metric from Google
            // This delay helps break the task into multiple frames, reducing interaction time.
            setTimeout(() => {
                this.$set(this.modals, modal, {
                    show,
                    ...(type && { type }),
                    ...(data && { data }),
                });
            }, 0);
        });
    },
};
</script>
