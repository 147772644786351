import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProductCard } from '../utils/adaptors';

export default async function fetchSubcategoriesService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}subcategory-products`,
        params,
    });

    if (!response.data.data) {
        return [];
    }

    return response.data.data
        .filter((category) => category.items && category.items.length > 0)
        .map((category) => ({
            sectionTitle: category.name,
            sectionPath: category.url,
            items: (category.items.map((item) => itemProductCard({
                ...item,
                queryParams: {
                    ch_id: response.data.query_params.ch_id,
                    ch_type: response.data.query_params.ch_type,
                },
            }))),
        }));
}
