var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.modals.user
        ? _c("modal-user", {
            attrs: { type: _vm.modals.user.type, data: _vm.modals.user.data },
            model: {
              value: _vm.modals.user.show,
              callback: function ($$v) {
                _vm.$set(_vm.modals.user, "show", $$v)
              },
              expression: "modals.user.show",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.gdpr
        ? _c("modal-gdpr", {
            attrs: {
              "hide-btn-edit": _vm.modals.gdpr.data.hideBtnEdit,
              "show-screen-edit": _vm.modals.gdpr.data.showScreenEdit,
            },
            model: {
              value: _vm.modals.gdpr.show,
              callback: function ($$v) {
                _vm.$set(_vm.modals.gdpr, "show", $$v)
              },
              expression: "modals.gdpr.show",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.addToCartRecommendations
        ? _c("modal-add-to-cart-recommendations", {
            attrs: { data: _vm.modals.addToCartRecommendations.data },
            model: {
              value: _vm.modals.addToCartRecommendations.show,
              callback: function ($$v) {
                _vm.$set(_vm.modals.addToCartRecommendations, "show", $$v)
              },
              expression: "modals.addToCartRecommendations.show",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }