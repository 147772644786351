import { EVENT, SOCIAL, SOURCE } from '../constants';
import { getDomainById } from '../../configs';

export default {
    methods: {
        registerUser() {
            const payload = {
                email: this.form.data.email,
                password: this.form.data.password,
                newsletterSubscription: this.form.data.newsletterSubscription,
            };

            this.$store.dispatch('user/register', payload)
                .then(() => {
                    this.loginUser(this.form.data.email, this.form.data.password, null, true);
                })
                .catch((error) => {
                    this.showServerError(error);
                    this.triggerLoadingState(null, false);
                });
        },

        async loginUser(identifier, password, loginType = null, isRegister = false) {
            this.triggerLoadingState(loginType, true);

            const tokens = await this.getUserToken(identifier, password, loginType);

            if (!tokens) {
                this.triggerLoadingState(loginType, false);
                return null;
            }

            const { accessToken, expiresIn } = tokens;

            this.$store.dispatch('storage/add', {
                key: 'api_v2_token',
                value: accessToken,
                expires: expiresIn,
            });

            this.$store.dispatch('cns/refreshCnsConnection').then();

            return this.$store.dispatch('user/fetchUser', { token: accessToken }).then(() => {
                this.openModalUser(null);

                this.$store.dispatch('datalayer/userInfo');
                if (isRegister) {
                    this.$store.dispatch('datalayer/register', {
                        userId: this.$store.state.user.uid,
                        type: 'form',
                    });
                }
                this.$store.dispatch('shared/components/fetchInlineControl', { topic: this.$route.name });
                this.$store.dispatch('datalayer/cookiePreferences', this.$store.state.ui.gdpr);

                if (this.nextUrl) this.$router.push(this.nextUrl);

                if (!this.$ssrContext) {
                    if (this.$route.fullPath.includes('/cart')) {
                        this.$store.dispatch('ui/setLoading', { showAppLoader: true });
                        window.location.reload();
                    }

                    window.postMessage(
                        {
                            source: SOURCE.SITE,
                            event_type: EVENT.TYPE.USER_LOGGED_IN,
                            data: {
                                userId: this.$store.state.user.uid,
                            },
                        },
                        '*',
                    );
                }
            }).catch((error) => {
                console.error('component action fetchUser: ', error);
            }).finally(() => {
                this.triggerLoadingState(loginType, false);
            });
        },

        getUserToken(identifier, password, type) {
            let payload = {
                username: identifier,
                password,
            };
            if (![null, 'autologin'].includes(type)) {
                payload = {
                    token: identifier,
                    type,
                };
            }

            const redirect = async (error) => {
                const query = `${this.$route.fullPath}${this.$route.fullPath.indexOf('?') !== -1 ? '&' : '?'}redirect-uuid=${error.response.data.data.redirectUuid}`;
                const domain = getDomainById(
                    this.$store.state.params.env,
                    error.response.data.data.countryId,
                    this.$store.state.params.country.platform,
                );
                const response = {
                    url: `//${domain}${query}`,
                };

                if (this.$isServer) {
                    throw response;
                } else {
                    window.location.href = response.url;
                }
            };

            return this.$store.dispatch('user/fetchUserToken', payload)
                .then((response) => response)
                .catch((error) => {
                    if (error.response.status === 301) {
                        redirect(error);
                        return;
                    }

                    this.showServerError(error, type);
                    this.triggerLoadingState(type, false);
                });
        },
        showServerError(serverError, type) {
            serverError.response.data.forEach((error) => {
                if (type === SOCIAL.SOCIAL_TYPES.FACEBOOK) {
                    if (typeof error.message === 'string') {
                        this.form.serverErrors.social = error.message;
                    } else {
                        [this.form.serverErrors.social] = error.message;
                    }

                    return;
                }

                if (error.field === 'User[email]') {
                    // TODO - removes when api send `error.message` string
                    if (typeof error.message === 'string') {
                        this.form.serverErrors.email = error.message;
                    } else {
                        [this.form.serverErrors.email] = error.message;
                    }
                } else if (error.field === 'User[password]') {
                    // TODO - removes when api send `error.message`  string
                    if (typeof error.message === 'string') {
                        this.form.serverErrors.password = error.message;
                    } else {
                        [this.form.serverErrors.password] = error.message;
                    }
                } else if (error.code === 'INVALID_USER_COUNTRY') {
                    this.form.serverErrors.general = error.message;
                } else if (error.code === 'SYSTEM_ERROR') {
                    this.form.serverErrors.general = this.$t('controllers_api_error_alert_title');
                } else if (error.field === 'User[token]') {
                    this.displayErrorMessage(error.message);
                    throw error;
                }
            });
        },

        triggerLoadingState(loginType, loading) {
            if (loginType === 'autologin') return;

            if (!loginType) {
                this.form.isLoading = loading;
            } else if (loginType !== 'jwt') {
                this.form.isSocialLoading[loginType] = loading;
            }
        },

        openModalUser(type) {
            this.$emitter.emit('modal', {
                modal: 'user',
                type,
            });
        },

        clearServerErrors(event, field) {
            if (event.key !== 'Enter' && this.form.serverErrors[field]) (this.form.serverErrors[field] = '');
        },

        displayErrorMessage(message) {
            this.$store.dispatch('ui/notifications/push', {
                type: 'toast',
                variant: 'danger',
                id: `notification-${new Date().getTime()}`,
                autoHide: false,
                content: {
                    body: message,
                },
            });
        },
    },
};
